import React, { useEffect, useState } from "react"; // Import React explicitly
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import axios from "axios";
import styles from "./style.module.css";
import { useI18nContext } from "../../context/I18nContext";
import Loading from "../../Loading";

const AboutService = () => {
  const [services, setServices] = useState([]);
  const { t, language } = useI18nContext();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const response = await axios.get(
          "https://dramcode.top/api/v1/services/?sort=createdAt",
          {
            withCredentials: true,
            headers: { "X-API-KEY": "k2017secret" }
          }
        );
        setLoading(false);
        setServices(response.data.data);
      } catch (error) {
        // console.error('Error fetching services:', error);
        setLoading(false);
        setError("Failed to load check your network");
      }
    };

    fetchSlides();
  }, []); // Empty dependency array means this runs once on component mount

  if (loading) {
    return (
      <div className="d-flex justify-center align-items-center m-4">
        <Loading />
      </div>
    );
  }
  if (error) {
    return (
      <div
        class="alert alert-danger d-flex justify-center align-items-center my-4 w-[80%] m-auto"
        role="alert"
      >
        {error}
      </div>
    );
  }
  return (
    <div>
      <h2 className="font-bold py-3 mt-10 z-50 text-center max-md:px-10">
        {t("Services.servicesTitle")}
      </h2>
      <div className={styles.line}></div>
      <div className="grid grid-cols-2 mx-4 max-tab:flex max-tab:flex-wrap max-tab:justify-center">
        <div
          className={`flex justify-content-evenly align-center ${styles.wavy}`}
        >
          <div className={styles.card}>
            <h5>{t("Services.About")}</h5>
            <h1 className="font-bolder my-2 ">
              DRAM CODE <br /> Company
            </h1>
            <h5 className="leading-7">{t("Services.description")}</h5>
          </div>
        </div>
        <div className="flex justify-between align-center flex-wrap max-lg:justify-center">
          {services.map((service) => (
            <div
              key={service._id}
              className={`w-52 max-tab:w-full ${styles.serviceH} flex justify-center align-center`}
            >
              <Link
                to={`/service/${service._id}`}
                className="no-underline flex flex-col justify-center align-center text-center"
              >
                <div
                  className={`flex justify-center align-center ${styles.circularImage}`}
                >
                  <Image
                    width={120}
                    height={120}
                    objectFit="contain"
                    src={service.image}
                    alt={`service : ${service.name}`}
                    className="p-4"
                    loading="lazy"
                  />
                </div>
                <p className="py-4 text-[#000] max-w-52 text-l">
                  {language === "ar" ? service.name_ar : service.name_en}
                </p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutService;
