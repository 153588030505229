import { useState, useEffect, useCallback } from "react";
import styles from "./services.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import { useI18nContext } from "../context/I18nContext";
import { useMediaQuery } from "react-responsive";
import Loading from "../Loading";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import axios from "axios";

const Slider = () => {
  const { t, language } = useI18nContext();
  const [slides, setSlides] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const isLgScreen = useMediaQuery({ query: "(min-width: 1024px)" });
  const isTabScreen = useMediaQuery({ query: "(min-width: 765px)" });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const fetchSlides = useCallback(async () => {
    try {
      const response = await axios.get(
        "https://dramcode.top/api/v1/services/?sort=createdAt",
        {
          withCredentials: true,
          headers: { "X-API-KEY": "k2017secret" }
        }
      );
      const data = response.data;
      const slidesArray = data.data;
      if (Array.isArray(slidesArray)) {
        const formattedSlides = slidesArray.map((slide) => ({
          name: language === "ar" ? slide.name_ar : slide.name_en,
          intro: language === "ar" ? slide.intro_ar : slide.intro_en,
          image: slide.image,
        }));
        setLoading(false)
        setSlides(formattedSlides);
      } else {
        // console.error("Unexpected data format:", data);
        setLoading(false)
        setError("Failed to load check your network");
      }
    } catch (error) {
      // console.error("Error fetching slides:", error);
      setLoading(false)
      setError("Failed to load check your network");
    }
  }, [language]);

  useEffect(() => {
    fetchSlides();
  }, [fetchSlides]);

  useEffect(() => {
    AOS.init({ duration: 500 });
  }, []);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return (
      <div class="alert alert-danger d-flex align-items-center" role="alert">
        <div>{error}</div>
      </div>
    );
  }

  return (
    <div
      className={`${styles.slider} max-tab:h-[70vh] max-mob3:h-[65vh]`}
      dir={language === "ar" ? "rtl" : "ltr"}
    >
      {/* <div className="position-relative d-flex mb-3 mt-3 align-items-center justify-content-center ">
        <h1 className="stroke-1 display-1 text-center inline px-3 mb-3 rounded-5 text-gray-100 bg-[--button]">
          {t("Services.ourServices")}
        </h1>
      </div> */}
      <Swiper
        slidesPerView={isLgScreen ? 3 : isTabScreen ? 2 : 1}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        modules={[Pagination, Autoplay]}
        className={`${styles.swiper}`}
        data-aos="fade-up"
      >
        {slides.map((slide, index) => (
          <SwiperSlide
            className={`${styles.swiperSlide} ${isLgScreen ? "h-[75vh]" : ""}`}
            key={index}
          >
            <div className={styles.contImages} data-aos="zoom-in">
              <img
                className={styles.slideImage}
                src={slide.image}
                width={500}
                height={500}
                alt="slide Name"
                loading="lazy"
              />
            </div>
            <h3 className={`${styles.slideName}`} data-aos="fade-right">
              {slide.name}
            </h3>
            <p className={`${styles.slidePara}`} data-aos="fade-left">
              {expandedIndex === index
                ? slide.intro
                : slide.intro.length > 50
                  ? `${slide.intro.substring(0, 105)}... `
                  : slide.intro}
              {slide.intro.length > 50 && (
                <button className="text-[--button]">
                  <Link
                    aria-label={`Read more about our ${slide.name} service`}
                    to={`/services`}
                    className="no-underline"
                  >
                    {t("Services.readMore")}
                  </Link>
                </button>
              )}
            </p>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;
